@import '../App/colors';

.navbar {
  .nav-item {
    .nav-link {
      color: $c-blue !important;
      font-weight: 700;
      transition: opacity 300ms;

      &:hover {
        opacity: 0.8;
      }
    }

    .rednav {
      color: $c-red !important;
    }
  }

  padding-bottom: 16px;

  &:after {
    width: 100%;
    height: 8px;
    content: " ";
    display: block;
    opacity: 0.25;
    background: $c-red;
    position: absolute;
    bottom: 0;
  }
}
