@import '../App/colors';


footer {
  background: $c-bg-footer;
  color: white;

  a{
    color: inherit;
    text-decoration: none;

    &:hover{
      color: $c-red;
    }
  }

  .logostrip {
    display: flex;
    justify-content: space-between;

    @media (max-width: 900px) {
      flex-direction: column;
      text-align: center;
    }
    img {
      height: 2.31rem;
      margin-bottom: 1.25rem;
    }
  }


  .heading {
    color: #fff;
    //font-family: Montserrat, sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    text-transform: uppercase;

    &::after {
      content: "";
      height: 2px;
      width: 4.7rem;
      display: block;
      background-color: #ff5252;
      margin-top: .32rem;
      margin-bottom: .94rem;
    }
  }

  .separator {
    content: "";
    height: 1px;
    margin: .625rem 0 1.875rem;
    display: block;
    background-color: #424242;
  }

  i {
    color: $c-red;
  }

  .smallline {
    color: #9e9e9e;
    //font-family: Lora,serif;
    font-weight: 400;
    font-size: .875rem;
  }

  .afterline {
    margin-bottom: 1.25rem;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      height: 1px;
      width: 13.125rem;
      display: block;
      background-color: #424242;
      bottom: -.625rem;
    }
  }

}