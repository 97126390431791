@import '../App/colors';

.wrap {
  background-color: $c-bg-grey !important;
  //background: url("../../img/fix_podklad_FEN.png") top center no-repeat $c-bg-grey;
  background-size: 125% auto;
  //margin-top: 80px;
  padding-top: 60px;
}


.podonadpis {
  font-weight: 800;
  font-size: 54px;
  text-transform: uppercase;
  line-height: 1;
  color: $c-brown;
  text-align: center;
}

.nadpis {
  font-weight: 800;
  font-size: 36px;
  color: $c-red;
  text-align: center;
}

.nadpismin {
  font-weight: 800;
  font-size: 22px;
  color: $c-red;
  text-align: center;
  line-height: 1.1;
}


.boxw {
  box-shadow: rgba(0, 0, 0, 0.04%) 0px 3px 5px;
  border-radius: 5px;
  //todo box shadow
  background: white;
  padding: 40px 20px;
  height: 100%;

  .ikona {
    color: $c-red;
    display: block;
    text-align: center;
    margin: 25px 0;
    font-size: 40px;
  }

  .boxtext {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
  }
}


.btnsblok {
  max-width: 450px;
  margin: 0 auto;

  .btn-login,  .btn-form {
    background: $c-red;
    border: $c-red;
    color: white;
  }

  .btn-regis {
    border: 1px solid $c-red;
    color: $c-red;
  }

  .btn-login, .btn-regis, .btn-form {
    line-height: 2;
    text-transform: uppercase;
    transition: opacity 300ms;

    &:hover {
      opacity: 0.8;
    }
  }
}

//.formpodo {
//
//  .inputform {
//    background: lightgrey;
//    color: black;
//    padding: 25px 15px;
//    border-radius: 10px;
//    font-weight: 600;
//  }

//.btnform {
//  //padding: 25px 15px;
//  border-radius: 10px;
//  background: $c-red !important;
//  border: $c-red;
//  color: white !important;
//  //font-weight: 800;
//  transition: opacity 300ms;
//
//  //margin: 30px auto;
//  display: block;
//
//  &:hover {
//    opacity: 0.8;
//  }
//}
//}
